import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createClient } from '@supabase/supabase-js'; // Import Supabase client
import axios from 'axios'
const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_KEY);


export const getLeadsContent = createAsyncThunk('/leads/content', async () => {
    const response = await supabase
    .from('projects')
    .select('name, budget, renewable_budget, deadline_date, indoor_area')
    .eq('owner_id', (await supabase.auth.getUser()).data.user.id)
    const ret = response;
    return ret;
})

export const leadsSlice = createSlice({
    name: 'leads',
    initialState: {
        isLoading: false,
        leads : []
    },
    reducers: {


        addNewLead: (state, action) => {
            let {newLeadObj} = action.payload
            state.leads = [...state.leads, newLeadObj]
        },

        editNewLead: (state, action) => {
            let {editLeadObj, editName} = action.payload
            let index = state.leads.findIndex((lead) => lead.name === editName)
            state.leads[index] = editLeadObj
        },

        backendDeleteLead: async(state, action) => {
            const { data, error } = await supabase
            .from('projects')
            .delete()
            .eq('name', state.leads[action.payload.index].name)
            .eq('owner_id', (await supabase.auth.getUser()).data.user.id)
        },

        deleteLead: (state, action) => {
            let {index} = action.payload
            state.leads.splice(index, 1)
        }
    },

    extraReducers: {
		[getLeadsContent.pending]: state => {
			state.isLoading = true
		},
		[getLeadsContent.fulfilled]: (state, action) => {
			state.leads = action.payload.data
			state.isLoading = false
		},
		[getLeadsContent.rejected]: state => {
			state.isLoading = false
		},
    }
})

export const { addNewLead, editNewLead, deleteLead, backendDeleteLead } = leadsSlice.actions

export default leadsSlice.reducer